import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const contentCreatorProjects = [
    {
      title: "Content Creator Project #1",
      description: "Test Description #1",
      imgUrl: projImg1,
    },
    {
      title: "Content Creator Project #2",
      description: "Test Description #2",
      imgUrl: projImg2,
    },
    {
      title: "Content Creator Project #3",
      description: "Test Description #3",
      imgUrl: projImg3,
    },
    {
      title: "Content Creator Project #4",
      description: "Test Description #4",
      imgUrl: projImg1,
    },
    {
      title: "Content Creator Project #5",
      description: "Test Description #5",
      imgUrl: projImg2,
    },
    {
      title: "Content Creator Project #6",
      description: "Test Description #6",
      imgUrl: projImg3,
    },
  ];

  const livestreamProductionProjects = [
    {
      title: "Livestream Production Project #1",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Livestream Production Project #2",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Livestream Production Project #3",
      description: "Design & Development",
      imgUrl: projImg3,
    },
  ];

  const videoEditorProjects = [
    {
      title: "Video Editor Project #1",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Video Editor Project #2",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Video Editor Project #3",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Video Editor Project #4",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Video Editor Project #5",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Video Editor Project #6",
      description: "Design & Development",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Here, you'll find a curated collection of my previous work across different fields, showcasing my skills in video editing, livestream production, and content creation. Each project highlights my dedication to quality and creativity. Select the tab that interests you to explore and learn more about each project in detail.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Content Creation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Livestream Production</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Video Editing</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          contentCreatorProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          livestreamProductionProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          videoEditorProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
